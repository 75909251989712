import React from 'react';
import { Route, Redirect } from 'react-router';
import { user } from '../../user';
import { routes } from '../../constants';
import queryString from 'query-string';

export const CustomRoute = ({
    component: Component,
    layout: Layout,
    allowAnonymous = true,
    allowRoles,
    allowFeatures,
    blockFeatureRedirectPath,
    title,
    children,
    ...rest }) => {
    const redirectUnathorizated = () => {
        const isDirectedToIssuanceMonitor = rest?.computedMatch?.path === routes.transactionDetail;
        const parsedParams = queryString.parse(rest.location.search);
        const token = parsedParams.token;
        const from = (rest.location.pathname || '') + (rest.location.search || '');
        if (isDirectedToIssuanceMonitor && token) {
            const pathname = routes.arrangersClientSignUpUrl(token);
            return <Redirect to={{ pathname, state: { from } }} />
        }
        return <Redirect to={{ pathname: routes.login, state: { from } }} />
    }

    if (!allowAnonymous) {
        if (!user.isAuthenticated()) {
            return redirectUnathorizated();
        } else if (allowRoles && allowRoles.length && !user.hasRoles(...allowRoles)) {
            return <Redirect to={{ pathname: routes.forbidden }} />
        } else if (allowFeatures && allowFeatures.length && !user.hasFeatures(...allowFeatures)) {
            return <Redirect to={{ pathname: blockFeatureRedirectPath || routes.forbidden }} />
        }
    }

    document.title = title || 'KTX ATS Platform';

    if (Layout) {
        return <Route {...rest} render={props => <Layout {...props}>{children || <Component {...props} />}</Layout>} />
    }

    return <Route {...rest} component={Component} />
}
