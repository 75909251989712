import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';
import { UnsavedChangesPopup } from './UnsavedChangesPopup';

export default class RouteLeavingGuard extends Component {
    state = {
        when: false,
        modalVisible: false,
        lastLocation: null,
        confirmedNavigation: false,
    };

    render = () => {
        const { modalVisible } = this.state;
        const {
            text,
            title,
            confirmButtonText = 'Confirm',
            cancelButtonText = 'Cancel' } = this.props;

        return (
            <>
                <Prompt message={this.handleBlockedNavigation} when={this.state.when} />
                {
                    modalVisible &&
                    <UnsavedChangesPopup
                        title={title}
                        text={text}
                        confirmButtonText={confirmButtonText}
                        cancelButtonText={cancelButtonText}
                        onConfirm={this.handleConfirmNavigationClick}
                        onCancel={this.handleCancel}
                    />
                }
            </>
        )
    }

    componentDidMount = () => this.setState({ when: true });

    componentDidUpdate = () => {
        const { shouldBlockNavigation } = this.props;

        if (shouldBlockNavigation) {
            window.onbeforeunload = () => {
                const shouldAsk =
                    !(document.activeElement &&
                        document.activeElement.href &&
                        document.activeElement.href.startsWith('mailto:'));

                if (shouldAsk) {
                    return true;
                }
            }
        } else {
            window.onbeforeunload = undefined;
        }
    }

    componentWillUnmount = () => {
        window.onbeforeunload = undefined;
    }

    showModal = location => this.setState({
        modalVisible: true,
        lastLocation: location,
    })

    closeModal = callback => this.setState({
        modalVisible: false
    }, callback)

    handleBlockedNavigation = nextLocation => {
        const { confirmedNavigation } = this.state;
        const { shouldBlockNavigation } = this.props;

        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            this.showModal(nextLocation)
            return false;
        }

        return true;
    }

    handleCancel = () => this.closeModal(() => {
        const { cancelNavigate } = this.props;
        const { lastLocation } = this.state;

        if (lastLocation && cancelNavigate) {
            this.setState({
                confirmedNavigation: true
            }, () => {
                cancelNavigate(lastLocation.pathname);
            });
        }
    })

    handleConfirmNavigationClick = () => this.closeModal(() => {
        const { navigate } = this.props;
        const { lastLocation } = this.state;

        if (lastLocation) {
            this.setState({
                confirmedNavigation: true
            }, () => {
                navigate(lastLocation.pathname, lastLocation.search, lastLocation.state);
            });
        }
    })
}
