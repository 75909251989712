import React, { useState } from 'react';
import { isRequesting } from '../../../utils';
import { RequestPopup } from './RequestPopup';
import { SettlementAgentAgreement } from '../../../types/bid-as-dealer/SettlementAgentAgreement';
import { RequestState } from '../../../constants/request-state';
import { RequestDirectBiddingContent } from './RequestDirectBiddingContent';

interface RequestDirectBiddingBannerProps {
    sendRequest: (clearingBankId?: number) => Promise<void>
    setContactsPopupVisible: (visible: boolean) => void
    agreement?: SettlementAgentAgreement
    requestStateSendRequest: RequestState
}

export const RequestDirectBiddingBanner = ({ sendRequest, agreement, setContactsPopupVisible, requestStateSendRequest }: RequestDirectBiddingBannerProps) => {
    const [popupVisible, setPopupVisible] = useState(false);

    const handleSendRequest = async (clearingBankId?: number) => {
        await sendRequest(clearingBankId);
        setPopupVisible(false);
    };
    return (
        <>
            <RequestDirectBiddingContent
                agreement={agreement}
                setContactsPopupVisible={setContactsPopupVisible}
                setPopupVisible={setPopupVisible} />
            <RequestPopup
                onClose={() => setPopupVisible(false)}
                inProgress={isRequesting(requestStateSendRequest)}
                handleSendRequest={handleSendRequest}
                visible={popupVisible}
            />
        </>
    )
}
