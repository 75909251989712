import React from 'react';
import { CompanySlim } from '../../types/company/CompanySlim';
import Popup from '../controls/Popup';
import { PopupBody, PopupFooter } from '../controls';
import { useCompanyUserList } from '../../effects/useCompanyUserList';
import { SettlementAgentContactsList } from '../common/settlment-agent-popup/SettlementAgentContactsList';

export const UsersPopup = ({ company, onClose }: { company: CompanySlim; onClose: () => void; }) => {
    const { requestStateGetUsers, users } = useCompanyUserList(company.id);

    return (
        <Popup
            modalClass="modal-settlement-agent-users"
            renderTitle={<h1>Contacts <span className="name-tag">{company.name}</span></h1>}
            onClose={onClose}
            renderInBody={true}
        >
            <PopupBody>
                <SettlementAgentContactsList users={users} requestStateGetUsers={requestStateGetUsers} />
            </PopupBody>
            <PopupFooter>
                <button className="btn btn-ghost" onClick={onClose}>
                    Close
                </button>
            </PopupFooter>
        </Popup>
    )
}
