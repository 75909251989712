import React from 'react';
import { Preloader } from '../../common';
import { Popup, PopupBody, PopupFooter } from '../../controls';
import { RequestToBidButton } from './RequestToBidButton';

interface Props {
    inProgress: boolean;
    onClose: () => void;
    handleSendRequest: (clearingBankId?: number) => void;
    visible: boolean;
    renderPopupFooter?: React.ReactNode;
}

export const RequestPopup = ({ handleSendRequest, inProgress, onClose, visible, renderPopupFooter }: Props) => {
    if (!visible) {
        return null;
    }

    return (
        <Popup
            title="Introducing KTX Trading"
            modalClass="introducing-direct-modal"
            onClose={onClose}
        >
            <PopupBody>
                <Preloader inProgress={inProgress}>
                    <p>Introducing KTX Trading: Access CLO liquidity seamlessly from both broker-dealers and the buy-side.</p>
                    <h2>Key Features</h2>
                    <ul className="list-bullet">
                        <li>Choose your preferred trading approach: DirectBidding (seller's identity is disclosed to participating broker-dealers) or All-to-All (seller remains completely anonymous). Choose between 100% Privacy and Anonymity or opt for transparency and relationship-driven execution.</li>
                        <li>Choose from a range of platform bidding protocols, including LiveBidding, Jump Ball, Top&nbsp;X, Best Foot Forward, or Standard.</li>
                        <li>Buyer identities are always kept anonymous for enhanced privacy.</li>
                        <li>Buyers enjoy automated, real-time feedback (Best, Cover, Third, Not in Top 3) and receive guaranteed post-trade color (Note: this feature may not apply to all trading approaches).</li>
                        <li>All bids are considered final and must remain firm for at least 30 minutes after the bidding window closes.</li>
                    </ul>
                    <h2>Settlement</h2>
                    <ul className="list-bullet">
                        <li>All-to-All (A2A) are settled by KopenTech Capital Markets LLC (MPID: KTCM), a FINRA-registered introducing broker-dealer and operator of this SEC-licensed Alternative Trading System (ATS).</li>
                        <li>Buyside DirectBidding trades are settled by KopenTech Capital Markets LLC (MPID: KTCM), a FINRA-registered introducing broker-dealer and operator of this SEC-licensed Alternative Trading System (ATS).</li>
                        <li>Dealer DirectBidding trades are settled by the winning broker-dealer.</li>
                        <li>Trade clearing is conducted by third-party clearing firm(s): MIRAE Asset Securities (USA) Inc. (MPID: MIRA).</li>
                    </ul>
                </Preloader>
            </PopupBody>
            <PopupFooter>
                {renderPopupFooter ? renderPopupFooter : <div className="flex-item-right">
                    <RequestToBidButton
                        onClick={handleSendRequest}
                        disabled={inProgress}
                    >
                        Request KTX Trading
                    </RequestToBidButton>
                </div>}
            </PopupFooter>
        </Popup>
    )
}
