import React, { useState } from 'react';
import { isRequesting } from '../../../utils';
import { RequesAllToAllTradingPopup } from './RequesAllToAllTradingPopup';
import { SettlementAgentAgreement } from '../../../types/bid-as-dealer/SettlementAgentAgreement';
import { RequestState } from '../../../constants/request-state';
import { RequestAllToAllTradingContent } from './RequestAllToAllTradingContent';

interface RequestAllToAllTradingProps {
    sendRequest: (clearingBankId?: number) => Promise<void>
    setContactsPopupVisible: (visible: boolean) => void
    agreement?: SettlementAgentAgreement
    requestStateSendRequest: RequestState
}

export const RequestAllToAllTrading = ({ sendRequest, agreement, setContactsPopupVisible, requestStateSendRequest }: RequestAllToAllTradingProps) => {
    const [popupVisible, setPopupVisible] = useState(false);

    const handleSendRequest = async (clearingBankId?: number) => {
        await sendRequest(clearingBankId);
        setPopupVisible(false);
    };

    return (
        <>
            <RequestAllToAllTradingContent
                agreement={agreement}
                setContactsPopupVisible={setContactsPopupVisible}
                setPopupVisible={setPopupVisible} />
            <RequesAllToAllTradingPopup
                onClose={() => setPopupVisible(false)}
                inProgress={isRequesting(requestStateSendRequest)}
                handleSendRequest={handleSendRequest}
                visible={popupVisible}
            />
        </>
    )
}
