import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorActions } from '../../actions';
import { RequestState } from '../../constants/request-state';
import { settlementAgentAgreementService } from '../../services';

export const useSettlementAgentRequest = () => {
    const dispatch = useDispatch();
    const [requestStateSendRequest, setRequestStateSendRequest] = useState(RequestState.none);

    const sendRequest = async (clearingBankId?: number) => {
        try {
            setRequestStateSendRequest(RequestState.request);
            await settlementAgentAgreementService.sendRequest({settlementAgentId: clearingBankId});
            setRequestStateSendRequest(RequestState.success);
        } catch (e) {
            setRequestStateSendRequest(RequestState.failure);
            dispatch(errorActions.error(e));
        }
    }

    return { sendRequest, requestStateSendRequest };
}
