import { useEffect, useState } from 'react';
import { keys } from 'lodash';
import moment from 'moment';
import Popup from '../controls/Popup';
import { Checkbox, PopupBody, PopupFooter } from '../controls';
import { ColumnBuilder } from '../bidding/common/table/columns/column-builder/ColumnBuilder';
import { IColumnDefinition } from '../bidding/common/table/types/ColumnDefinition';
import { EmailLink } from '../bidding/common/EmailLink';
import { Table } from '../bidding/common/table';
import { user } from '../../user';
import { isRequesting, isRequestSuccess, moneyUtils } from '../../utils';
import { RejectedTrade } from '../../types/trades/RejectedTrade';
import { useResolveTrade } from './useResoveTrade';
import { useHistoryListener } from '../../effects/useHistoryListener';
import { useRejectedTrades } from '../../effects/data-accessors/useRejectedTrades';
import { OnHoverTooltip } from "../common";
import { constants } from '../../constants';
import { StatusMessageSectionType } from "../../types/state/NotificationState";
import { StatusMessageSection } from "../status-message/StatusMessageSection";

export const RejectedTradesPopup = () => {
    const currentUser = user.current()!;

    const [visible, setVisible] = useState(false);

    const { rejectedTrades } = useRejectedTrades(!currentUser.isClearingBank)

    useHistoryListener(
        () => !visible && setVisible(true),
        currentUser.isClearingBank && Boolean(rejectedTrades.length)
    )

    useEffect(() => {
        if (rejectedTrades.length) {
            setVisible(true)
        }
    }, [rejectedTrades]);

    return visible ? <RejectedTradePopupContent trades={rejectedTrades} onClose={() => setVisible(false)} /> : null;
}

interface Props {
    trades: RejectedTrade[];
    onClose: () => void;
}

const RejectedTradePopupContent = ({ trades, onClose }: Props) => {
    const [selectedTrades, setSelectedTrades] = useState<{[ket: string]: boolean}>({});

    const { requestStateResolve, resolveTrades } = useResolveTrade();

    const isRequestingResolve = isRequesting(requestStateResolve);

    useEffect(() => {
        if (isRequestSuccess(requestStateResolve)) {
            setSelectedTrades({});
            if (!trades.length) {
                onClose();
            }
        }
    }, [requestStateResolve, trades, onClose])

    const handleSetSelectedTrades = (reference: string) => {
        const trades = {...selectedTrades};
        if (selectedTrades[reference]) {
            delete trades[reference];
        } else {
            trades[reference] = true;
        }
        setSelectedTrades(trades);
    }

    const handleConfirm = () => {
        resolveTrades(keys(selectedTrades));
    }

    const handleClose = () => {
        if (!isRequestingResolve) {
            onClose()
        }
    }

    const getColumns = () => {
        const columns: IColumnDefinition<RejectedTrade>[] = [
            {
                columnKey: 'ticker',
                renderColumnHeaderContent: () => 'Ticker',
                renderColumnContent: (trade) => trade.ticker,
                headerClassName: 'data-list-cell-lg',
                bodyClassName: 'data-list-cell-lg'
            }, {
                columnKey: 'price',
                renderColumnHeaderContent: () => 'Price',
                renderColumnContent: (trade) => moneyUtils.money(trade.price, true, 4),
                headerClassName: 'text-right',
                bodyClassName: 'text-right'
            }, {
                columnKey: 'size',
                renderColumnHeaderContent: () => 'Size',
                renderColumnContent: (trade) => moneyUtils.money(trade.size),
                headerClassName: 'text-right',
                bodyClassName: 'text-right'
            }, {
                columnKey: 'trade-date',
                renderColumnHeaderContent: () => 'Trade Date',
                renderColumnContent: (trade) => moment.utc(trade.tradeDate).format('MM/DD/YYYY'),
                headerClassName: '',
                bodyClassName: ''
            }, {
                columnKey: 'rejected-by',
                renderColumnHeaderContent: () => 'Rejected By',
                renderColumnContent: (trade) => `${trade.affirmedRejectedBy.firstName} ${trade.affirmedRejectedBy.lastName}`,
                headerClassName: 'data-list-cell-lg',
                bodyClassName: 'data-list-cell-lg'
            }, {
                columnKey: 'email',
                renderColumnHeaderContent: () => 'Email',
                renderColumnContent: (trade) => (
                    <OnHoverTooltip overlay={trade.affirmedRejectedBy.email}>
                        <EmailLink email={trade.affirmedRejectedBy.email} />
                    </OnHoverTooltip>
                ),
                headerClassName: 'data-list-cell-xl',
                bodyClassName: 'data-list-cell-xl'
            }, {
                columnKey: 'phone',
                renderColumnHeaderContent: () => 'Phone',
                renderColumnContent: (trade) => trade.affirmedRejectedBy.phone || constants.emptyPlaceholder,
                headerClassName: 'data-list-cell-sm',
                bodyClassName: 'data-list-cell-sm'
            }, {
                columnKey: 'reject-reason',
                renderColumnHeaderContent: () => 'Reject Reason',
                renderColumnContent: (trade) => <OnHoverTooltip overlay={trade.rejectReason}>{trade.rejectReason}</OnHoverTooltip>,
                headerClassName: 'data-list-cell-xl',
                bodyClassName: 'data-list-cell-xl'
            }, {
                columnKey: 'resolve',
                renderColumnHeaderContent: () => '',
                renderColumnContent: (trade) => (
                    <Checkbox
                        label="Resolved"
                        onChange={() => handleSetSelectedTrades(String(trade.id))}
                        checked={selectedTrades[trade.id]}
                        disabled={trade.resolved || isRequestingResolve}
                    />
                ),
                headerClassName: '',
                bodyClassName: ''
            },
        ]
        return columns.map(c => new ColumnBuilder(c));
    }
    return (
        <Popup modalClass="rejected-trades-popup" title="Rejected Trades" onClose={handleClose}>
            <PopupBody>
                <StatusMessageSection type={StatusMessageSectionType.Error}>
                    One or more trades were rejected. Please contact the company users for more information.<br />
                    Select the trades to mark as resolved to NOT show to all recipients again.
                </StatusMessageSection>
                <Table columns={getColumns()} dataItems={trades} className="data-list-striped" />
            </PopupBody>
            <PopupFooter>
                <button
                    className="btn btn-ghost"
                    onClick={handleClose}
                    disabled={isRequestingResolve}
                >
                    Close
                </button>
                <button
                    className="btn btn-main"
                    onClick={handleConfirm}
                    disabled={!keys(selectedTrades).length || isRequestingResolve}
                >
                    Confirm
                </button>
            </PopupFooter>
        </Popup>
    )
}
