import { useEffect, useState } from 'react';
import { isRequestSuccess, isRequesting } from '../../../utils';
import { RequesAllToAllTradingPopup } from './RequesAllToAllTradingPopup';
import { useAgreement } from '../../seller-buyside/useAgreement';
import { useSettlementAgentRequest } from '../useSettlementAgentRequest';
import { user } from '../../../user/user';
import { constants } from '../../../constants';
import { useDispatch } from 'react-redux';
import { useSetOnBoardingTooltipCountRequest } from '../../../effects/useSetOnBoardingTooltipCountRequest';
import { OnBoardingTooltipType } from '../../../types/onboarding-tooltips/OnBoardingTooltipType';
import { Checkbox } from '../../controls';

export const RequestAllToAllAutoOpener = () => {
    const dispatch = useDispatch();

    const maxWatchCount = constants.requestAllToAllTradingPopupMaxWatchCount;

    const { agreement, requestState: agreementsRequestStatus } = useAgreement();

    const [visible, setVisible] = useState(false);
    const [dontAscAgain, setDontAscAgain] = useState(false);

    const { setCount } = useSetOnBoardingTooltipCountRequest();

    const { sendRequest, requestStateSendRequest } = useSettlementAgentRequest();

    const onBoardingTooltipCounters = user.getOnBoardingTooltipCounters()
    const allToAllPopCount = onBoardingTooltipCounters[OnBoardingTooltipType.allToAllPopup];

    useEffect(() => {
        const isPopupClosedManually = localStorage.getItem(constants.requestAllToAllPopupLocalStorageKey);
        if (
            !visible &&
            isRequestSuccess(agreementsRequestStatus) &&
            !isPopupClosedManually &&
            allToAllPopCount < maxWatchCount &&
            !agreement
        ) {
            setVisible(true);
        }
    }, [
        agreementsRequestStatus,
        allToAllPopCount,
        agreement,
        visible,
        maxWatchCount,
        dispatch
    ]);

    if (!visible) {
        return null;
    }

    const handleSetCount = () => 
        setCount(
            dontAscAgain ? maxWatchCount : allToAllPopCount + 1,
            OnBoardingTooltipType.allToAllPopup
        );

    const handleClosePopup = () => {
        if (!isRequesting(requestStateSendRequest)) {
            localStorage.setItem(constants.requestAllToAllPopupLocalStorageKey, JSON.stringify(true));
            handleSetCount();
            setVisible(false);
        }
    };


    const handleSend = async (clearingBankId?: number) => {
        await sendRequest(clearingBankId);
        handleSetCount();
        setVisible(false);
    };

    const renderCheckbox = () => (
        <Checkbox
            label="Do not ask me again"
            checked={dontAscAgain}
            disabled={isRequesting(requestStateSendRequest)}
            onChange={() => setDontAscAgain(!dontAscAgain)}
        />
    )

    return (
        <RequesAllToAllTradingPopup
            renderCheckbox={renderCheckbox}
            onClose={handleClosePopup}
            inProgress={isRequesting(requestStateSendRequest)}
            handleSendRequest={handleSend}
            visible={visible}
        />
    )
}
