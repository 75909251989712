import { SettlementAgreementStatus } from '../../types/bid-as-dealer/SettlementAgreementStatus';
import { isRequestSuccess } from '../../utils';
import cn from 'classnames';
import IconSVG from '../../styles/svg-icons';
import { useSettlementAgentRequest } from './useSettlementAgentRequest';
import { useAgreement } from '../seller-buyside/useAgreement';
import { ShowFor } from '../access';
import { roles } from '../../constants';
import { RequestAllToAllTrading } from './request-all-to-all-traiding/RequestAllToAllTrading';
import { useState } from 'react';
import { UsersPopup } from './UsersPopup';
import { RequestDirectBiddingBanner } from './request-direct-bidding/RequestDirectBiddingBanner';
import { user } from '../../user/user';

export const RequestSettlementAgentAgreementBanner = () => {
    const [contactsPopupVisible, setContactsPopupVisible] = useState(false);
    const { agreement, requestState: agreementsRequestState } = useAgreement();
    const { sendRequest, requestStateSendRequest } = useSettlementAgentRequest();

    if (!isRequestSuccess(agreementsRequestState) || agreement?.agreementStatus === SettlementAgreementStatus.confirmed || user.current()?.isClearingBank) {
        return null
    }

    return (
        <div className={
            cn("banner request-direct-bidding-banner",
                { 'suspended': agreement && agreement.agreementStatus === SettlementAgreementStatus.rejected }
            )}
        >
            <IconSVG name="info" width={16} height={16} />
            <ShowFor roles={roles.bd()}>
                <RequestAllToAllTrading
                    agreement={agreement}
                    sendRequest={sendRequest}
                    requestStateSendRequest={requestStateSendRequest}
                    setContactsPopupVisible={setContactsPopupVisible}
                />
            </ShowFor>
            <ShowFor roles={roles.seller()}>
                <RequestDirectBiddingBanner
                    agreement={agreement}
                    sendRequest={sendRequest}
                    requestStateSendRequest={requestStateSendRequest}
                    setContactsPopupVisible={setContactsPopupVisible}
                />
            </ShowFor>
            {
                contactsPopupVisible && agreement?.settlementAgent && (
                    <UsersPopup
                        company={agreement?.settlementAgent}
                        onClose={() => setContactsPopupVisible(false)}
                    />
                )
            }
        </div>
    )
}