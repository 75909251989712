import { Preloader } from '../../common';
import { Popup, PopupBody, PopupFooter } from '../../controls';
import { RequestToBidButton } from '../request-direct-bidding/RequestToBidButton';

interface Props {
    renderCheckbox?: () => React.ReactNode;
    inProgress: boolean;
    onClose: () => void;
    handleSendRequest: (clearingBankId?: number) => void;
    visible: boolean;
}

export const RequesAllToAllTradingPopup = ({ inProgress, onClose, handleSendRequest, visible, renderCheckbox }: Props) => {

    if (!visible) {
        return null;
    }

    return (
        <Popup
            title="Introducing All-to-All Trading"
            modalClass="introducing-direct-modal"
            onClose={onClose}
        >
            <PopupBody>
                <Preloader inProgress={inProgress}>
                    <h2>Key Features</h2>
                    <ul className="list-bullet">
                        <li>Looking for privacy and anonymity in trade execution? Send anonymous BWICs to both buy-side and sell-side platform members with our All-to-All trading protocol.</li>
                        <li>Choose from a range of platform bidding protocols, including LiveBidding, Jump Ball, Top&nbsp;X, Best Foot Forward, or Standard.</li>
                        <li>Receive anonymized bids from platform members.</li>
                        <li>Easily provide automatic or manual bid feedback.</li>
                        <li>The BWIC seller retains the right to trade or DNT (Do Not Trade).</li>
                    </ul>
                    <h2>Settlement</h2>
                    <ul className="list-bullet">
                        <li>All trades are settled by KopenTech Capital Markets LLC (MPID: KTCM), a FINRA-registered introducing broker-dealer and operator of this SEC-licensed Alternative Trading System (ATS).</li>
                        <li>Trade clearing is conducted by third-party clearing firm(s): MIRAE Asset Securities (USA) Inc. (MPID: MIRA).</li>
                    </ul>
                </Preloader>
            </PopupBody>
            <PopupFooter>
                {renderCheckbox ? renderCheckbox() : null}
                <div className="flex-item-right">
                    <RequestToBidButton
                        onClick={handleSendRequest}
                        disabled={inProgress}
                    >
                        SEND REQUEST
                    </RequestToBidButton>
                </div>
            </PopupFooter>
        </Popup>
    )
}
