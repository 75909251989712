import * as React from 'react';
import { Popup, PopupBody, PopupFooter } from '../controls';

interface Props {
    title?: string;
    text?: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    onConfirm: () => void;
    onCancel: () => void;
}

export function UnsavedChangesPopup({
    title,
    text,
    confirmButtonText = 'Confirm',
    cancelButtonText = 'Cancel',
    onConfirm,
    onCancel }: Props) {
    return (
        <Popup
            modalClass="modal-confirmation"
            title={title || 'Unsaved Changes'}
            onClose={onCancel}
        >
            <PopupBody>
                <p className="status-message alert">
                    <i className="icon icon-warning" />
                    <span className="status-message-cnt">{text || 'You have unsaved changes. Are you sure you want to leave?'}</span>
                </p>
            </PopupBody>
            <PopupFooter>
                <button autoFocus className="btn btn-ghost" onClick={onCancel}>{cancelButtonText}</button>
                <button className="btn btn-main" onClick={onConfirm}>{confirmButtonText}</button>
            </PopupFooter>
        </Popup>
    );
}
