import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { user } from '../../user';
import { amrPipelineCommonActions } from '../../actions';
import { isRequestSuccess } from '../../utils';
import { Preloader } from '../common';

export function WithAmrRegistration({ component: Component, ...props }) {
    const dispatch = useDispatch();
    const amrUserRegistrationRequestState = useSelector(
        (state) => state.issuanceMonitor.amrPipelineCommon.requestState,
    );

    useEffect(() => {
        if (user.current()) {
            dispatch(amrPipelineCommonActions.registerUser());
        }

        return () => {
            dispatch(amrPipelineCommonActions.reset());
        }
    }, [dispatch]);

    const handshakePending = !isRequestSuccess(amrUserRegistrationRequestState) && user.isAuthenticated();

    if (handshakePending) {
        return (
            <div className="container-flex position-relative">
                <Preloader inProgress />
            </div>
        );
    }

    return <Component {...props} />;
}
