import React, { useEffect, useMemo, useState } from 'react';
import { useBrokerDealers } from '../../../effects/useBrokerDealers'

interface Props {
    children: React.ReactNode;
    disabled: boolean;
    onClick: (clearingBankId?: number) => void;
}

export function RequestToBidButton(props: Props) {
    return process.env.REACT_APP_SEND_REQUEST_TO_CUSTOM_SETTLEMENT_AGENT_ENABLED === 'true'
        ? <RequestToBidButtonForTest {...props} />
        : <RequestToBidButtonDefault {...props} />
}

function RequestToBidButtonForTest({ children, onClick, disabled }: Props) {
    const brokerDealerListState = useBrokerDealers();
    const clearingBanks = useMemo(
        () => brokerDealerListState.items.filter((item) => item.isClearingBank),
        [brokerDealerListState.items]
    );

    const [selectedCBId, setSelectedCBId] = useState(0);

    useEffect(() => {
        if(clearingBanks && clearingBanks.length) {
            setSelectedCBId(clearingBanks[0].id)
        }
    }, [clearingBanks])

    const handleClick = () => {
        onClick(selectedCBId)
    }

    return (
        <>
            <select value={selectedCBId} onChange={(e) => setSelectedCBId(Number(e.target.value))}>
                {clearingBanks.map((cb) => (
                    <option key={cb.id} value={cb.id}>{cb.name}</option>
                ))}
            </select>
            <button onClick={handleClick} disabled={disabled} className="btn btn-main">
                {children}
            </button>
        </>
    )
}

function RequestToBidButtonDefault({ children, onClick, disabled }: Props) {
    return (
        <button onClick={() => onClick()} disabled={disabled} className="btn btn-main">
            {children}
        </button>
    );
}
