import React, { useEffect, useState } from 'react';
import { Checkbox } from '../../controls';
import { useDispatch } from 'react-redux';
import { isRequesting, isRequestSuccess } from '../../../utils';
import { user } from '../../../user';
import { useBrokerDealers } from '../../../effects/useBrokerDealers';
import { constants } from '../../../constants';
import { useAgreement } from '../../seller-buyside/useAgreement';
import { RequestPopup } from './RequestPopup';
import { useSettlementAgentRequest } from '../useSettlementAgentRequest';
import { RequestToBidButton } from './RequestToBidButton';
import { useSetOnBoardingTooltipCountRequest } from '../../../effects/useSetOnBoardingTooltipCountRequest';
import { OnBoardingTooltipType } from '../../../types/onboarding-tooltips/OnBoardingTooltipType';

export const RequestDirectBiddingAutoOpener: React.FC = () => {
    const dispatch = useDispatch();

    const maxWatchCount = constants.introduceDirectBiddingPopupMaxWatchCount;

    const brokerDealerListState = useBrokerDealers();
    const brokerDealerListRequestState = brokerDealerListState.requestState;

    const { agreement, requestState: agreementsRequestStatus } = useAgreement();

    const [visible, setVisible] = useState(false);
    const { setCount } = useSetOnBoardingTooltipCountRequest();

    const { sendRequest, requestStateSendRequest } = useSettlementAgentRequest();

    const [dontAscAgain, setDontAscAgain] = useState(false);

    const directBiddingPopupCount = user.getOnBoardingTooltipCounters()?.DirectBiddingPopup;

    useEffect(() => {
        const isPopupClosedManually = localStorage.getItem(constants.requestDirectBiddingPopupLocalStorageKey);
        if (
            !visible &&
            isRequestSuccess(brokerDealerListRequestState) &&
            isRequestSuccess(agreementsRequestStatus) &&
            !isPopupClosedManually &&
            directBiddingPopupCount < maxWatchCount &&
            !agreement
        ) {
            setVisible(true);
        }
    }, [
        brokerDealerListRequestState,
        agreementsRequestStatus,
        directBiddingPopupCount,
        agreement,
        visible,
        maxWatchCount,
        dispatch
    ]);

    if (!visible) {
        return null;
    }

    const handleSetCount = () =>
        setCount(
            dontAscAgain ? maxWatchCount : directBiddingPopupCount + 1,
            OnBoardingTooltipType.directBiddingPopup
        );

    const handleClosePopup = () => {
        if (!isRequesting(requestStateSendRequest)) {
            localStorage.setItem('requestDirectBiddingPopupClosedManually', JSON.stringify(true));
            handleSetCount();
            setVisible(false);
        }
    };


    const handleSend = async (clearingBankId?: number) => {
        await sendRequest(clearingBankId);
        handleSetCount();
        setVisible(false);
    };

    return (
        <RequestPopup
            onClose={handleClosePopup}
            inProgress={isRequesting(requestStateSendRequest)}
            handleSendRequest={handleSend}
            visible={visible}
            renderPopupFooter={(
                <>
                    <Checkbox
                        label="Do not ask me again"
                        checked={dontAscAgain}
                        disabled={isRequesting(requestStateSendRequest)}
                        onChange={() => setDontAscAgain(!dontAscAgain)}
                    />
                    <RequestToBidButton
                        onClick={handleSend}
                        disabled={isRequesting(requestStateSendRequest)}
                    >
                        Request KTX Trading
                    </RequestToBidButton>
                </>
            )}
        />
    )
};
