import { FeatureButton } from '../../access/FeatureButton';
import { SettlementAgreementStatus } from '../../../types/bid-as-dealer/SettlementAgreementStatus';
import { SettlementAgentAgreement } from '../../../types/bid-as-dealer/SettlementAgentAgreement';
import { RoleActionBlocker } from '../../access/RoleActionBlocker';
import { roles } from '../../../constants/roles';

interface RequestDirectBiddingContentProps {
    setContactsPopupVisible: (visible: boolean) => void
    setPopupVisible: (visible: boolean) => void
    agreement?: SettlementAgentAgreement
}

export const RequestDirectBiddingContent = ({ agreement, setContactsPopupVisible, setPopupVisible }: RequestDirectBiddingContentProps) => {
    switch (agreement?.agreementStatus) {
        case SettlementAgreementStatus.rejected:
            return (
                <BannerSuspendedState
                    companyName={agreement.settlementAgent.name}
                    onOpenContactsPopup={() => setContactsPopupVisible(true)}
                />
            )
        case SettlementAgreementStatus.pending:
            return (
                <BannerPendingState
                    companyName={agreement.settlementAgent.name}
                    onOpenContactsPopup={() => setContactsPopupVisible(true)}
                />
            )
        default:
            return <BannerNoneState onShowVisibleRequestPopup={() => setPopupVisible(true)} />;
    }
}

function BannerNoneState({ onShowVisibleRequestPopup }: { onShowVisibleRequestPopup: () => void }) {
    return (
        <>
            Request approval from KopenTech Capital Markets to enable DirectBidding™ and All-to-All trading protocols.
            <div className="links">
                <a
                    className="text-nowrap"
                    href="https://kopentech.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                >Read More</a>or
                <RoleActionBlocker roles={[roles.SellerTrader, roles.BrokerDealerTrader]}>
                    <FeatureButton
                        className="btn btn-main btn-sm"
                        onClick={onShowVisibleRequestPopup}
                    >
                        SEND REQUEST
                    </FeatureButton>
                </RoleActionBlocker>
            </div>
        </>
    )
}

function BannerPendingState(
    {
        companyName,
        onOpenContactsPopup
    }: { companyName: string, onOpenContactsPopup: () => void }) {
    return (
        <>
            The bidding request has been successfully submitted. Please contact&nbsp;<button className="btn-link btn-link-lg secondary regular" onClick={onOpenContactsPopup}>{companyName}</button>&nbsp;for more details.
        </>
    )
}

function BannerSuspendedState(
    {
        companyName,
        onOpenContactsPopup
    }: { companyName: string, onOpenContactsPopup: () => void }) {
    return (
        <>
            The bidding request has been suspended. Please contact&nbsp;<button className="btn-link btn-link-lg secondary regular" onClick={onOpenContactsPopup}>{companyName}</button>&nbsp;for more details.
        </>
    )
}