import { useState } from 'react';
import { RequestState } from '../../constants/request-state';
import { tradeReportingService } from '../../services/tradeReportingService';
import { useDispatch } from 'react-redux';
import { errorActions } from '../../actions';
import { rejectedTradeActions } from '../../actions/entities/rejected-trades.actions';

export const useResolveTrade = () => {
    const dispatch = useDispatch();
    const [requestStateResolve, setRequestSateResolve] = useState(RequestState.none)
    const resolveTrades = async (tradesReferences: string[]) => {
        setRequestSateResolve(RequestState.request)
        try {
            await tradeReportingService.setResolveTrades(tradesReferences);
            setRequestSateResolve(RequestState.success);
            dispatch(rejectedTradeActions.setTradesAsResolved(tradesReferences))
        } catch (e) {
            setRequestSateResolve(RequestState.failure)
            dispatch(errorActions.unexpectedError(e))
        }
    }

    return {requestStateResolve, resolveTrades}
}
